@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

  html,
  body {
    font-family: 'Poppins', Arial, Helvetica, sans-serif;
    @apply text-black;
  }

  h2 {
    @apply text-lg;
  }
}

@layer components {
  .grid-dashboard {
    & * {
      @apply row-span-1;
    }
    & > :nth-child(3),
    & > :nth-child(4) {
      grid-template-columns: 1fr;
      grid-column: auto / span 2;
      @apply max-lg:col-auto;
    }
    & > :nth-child(3) {
      margin-bottom: -44px;
      @apply max-lg:mb-0;
    }
    & > :nth-child(4) {
      margin-top: -44px;
      @apply max-lg:mt-0;
    }
  }

  .table-default {
    @apply min-w-full table-auto border border-primary/20;

    & th,
    & td {
      @apply px-2 text-start py-4 text-xs;
    }

    & th {
      @apply bg-gray-50 font-medium;
    }
    & th:last-child {
      @apply w-1;
    }
  }

  .modal-content {
    @apply md:max-w-[424px] w-full bg-white rounded-3xl p-4;
  }

  .modal-overlay {
    @apply bg-black/20 w-full h-full fixed inset-0 flex items-center justify-center;
  }

  .loading-page {
    @apply w-full h-full flex items-center justify-center;
  }
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .thin-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: #c6c6c6 transparent;
    @apply [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 [&::-webkit-scrollbar-thumb]:rounded-xl [&::-webkit-scrollbar-track]:bg-transparent;
  }
}
